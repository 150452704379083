<script setup lang="ts">
const { $httpCookies } = useNuxtApp()
const { isMobile, isTablet } = useBreakpoint()
const route = useRoute()

const isForbiddenRoute = computed(() =>
  Boolean(
    ['legal-', 'house-preorder'].some((routeName) =>
      route.name?.toString().startsWith(routeName),
    ),
  ),
)

const maxAgeInSeconds = 3600 * 24 * 30
const showModal = ref(false)
const timeMills = ref(15000)
const timeout = ref<NodeJS.Timeout>()

const startTimer = (millis: number) => {
  if (!$httpCookies.get('close_popin_nl'))
    timeout.value = setTimeout(onExpires, millis)
}
const onActivity = () => {
  if (timeout.value) clearTimeout(timeout.value)
  if (!showModal.value && !isForbiddenRoute.value) startTimer(timeMills.value)
}
const onExpires = () => {
  timeout.value = 0 as unknown as NodeJS.Timeout
  // Do not show the popin in legal/terms pages
  showModal.value = !isForbiddenRoute.value
}
const closeModal = () => {
  showModal.value = false
  setLocalStorage()
}
const setLocalStorage = () =>
  $httpCookies.set('close_popin_nl', true, {
    maxAge: maxAgeInSeconds,
  })

onMounted(() => {
  document.addEventListener('mousemove', onActivity)
  document.addEventListener('keydown', onActivity)
  document.addEventListener('touchstart', onActivity)
})
onUnmounted(() => {
  document.removeEventListener('mousemove', onActivity)
  document.removeEventListener('keydown', onActivity)
  document.removeEventListener('touchstart', onActivity)
})

watch(
  isForbiddenRoute,
  (newValue) => {
    if (newValue) clearTimeout(timeout.value)
    else startTimer(timeMills.value)
  },
  { immediate: import.meta.browser },
)

const sizeImage = computed(() => {
  if (isMobile.value) {
    return { height: '201', width: '343' }
  }

  const sizes = isTablet.value
    ? { height: '239', width: '486' }
    : { height: '374', width: '407' }

  return sizes
})
</script>

<template>
  <LazyBaseModalV2
    v-model="showModal"
    data-cy="modal-newsletter"
    no-margin
    no-rounded
    size="m"
    @close="setLocalStorage"
  >
    <template #header>
      <div class="relative">
        <button
          class="absolute right-4 top-4 z-10 flex size-8 items-center justify-center rounded-full bg-white"
          type="button"
          @click="closeModal"
        >
          <BaseIcon name="close" />
        </button>
      </div>
    </template>
    <template #content>
      <BaseNewsletter :size-image="sizeImage" source="pop_in">
        <template #after-text>
          <BaseButton
            class="text-md"
            color="black"
            variant="link"
            data-cy="close-modal"
            @click="closeModal"
          >
            {{ $t('global.close') }}
          </BaseButton>
        </template>
      </BaseNewsletter>
    </template>
  </LazyBaseModalV2>
</template>
